import React, { createContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Auth, Hub } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { getEmailFromCognitoUser, getFirstNameFromCognitoUser, getLastNameFromCognitoUser, getSubFromCognitoUser } from './getUserAttribute';
import { fetchMemberForEmailV2, createMember } from '../../apis';
import { makeid } from '../../helpers';
import * as memberActions from '../../redux/actions/memberActions';
import * as memberAssociationsActions from '../../redux/actions/memberAssociationsActions';
import { IPlayerProfile } from '../../models/PlayerProfile';
import fetchPlayerProfile from '../../apis/fetchPlayerProfile';

type Await<T> = T extends {
  then(onfulfilled?: (value: infer U) => unknown): unknown;
}
  ? U
  : T;
type Member = Await<ReturnType<typeof fetchMemberForEmailV2>> | undefined;
type RefreshMember = () => any;
type UpdateMember = (data: Member) => any;
export type MemberContexts = {
  member: Member | undefined;
  refresh: RefreshMember;
  update: UpdateMember;
};

export const CognitoAuthUserContext = createContext<CognitoUser | undefined>(undefined);
export const MemberContext = createContext<MemberContexts>({
  member: undefined,
  refresh: () => {},
  update: () => {},
});

type Props = {
  loggedOutComponent: React.ReactNode;
  children: React.ReactNode;
  memberState: any;
  dispatchSetMember: (data: Member) => void;
  dispatchMemberProfile: (memberProfile: IPlayerProfile) => void;
  dispatchGetMemberAssociations: (data: any) => void;
};

const CognitoAuthProvider: React.FC<Props> = (props) => {
  const { loggedOutComponent, children, dispatchSetMember, dispatchMemberProfile, dispatchGetMemberAssociations } = props;
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  let [user, setUser] = useState<CognitoUser | undefined>();
  let [member, setMember] = useState<Member | undefined>();
  let [refreshMember, setRefreshMember] = useState<RefreshMember>(() => () => {});
  let [updateMember, setUpdateMember] = useState<UpdateMember>(() => () => {});

  const memberContext = useMemo<MemberContexts>(
    () => ({
      member,
      refresh: refreshMember,
      update: updateMember,
    }),
    [member, refreshMember, updateMember],
  );

  useEffect(() => {
    const getMemberAssociations = (memberData: any) => {
      const member_id = memberData ? memberData.member_id : '';
      if (member_id !== '' && member_id !== null) {
        let data: any = {};
        data.member_id = member_id;
        dispatchGetMemberAssociations(data);
      }
    };

    const updateUser = async (authState?: any) => {
      try {
        let member;
        const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;
        const email_address = await getEmailFromCognitoUser(user);

        setRefreshMember(() => async () => {
          const member = await fetchMemberForEmailV2(email_address);
          if (window.location.pathname !== '/tscoadmin') {
            const memberProfile = await fetchPlayerProfile(member.member_id);
            dispatchMemberProfile(memberProfile!);
            getMemberAssociations(member);
          }
          setMember(member);
          dispatchSetMember(member);
        });

        setUpdateMember(() => async (data: any) => {
          setMember(data);
          dispatchSetMember(data);
        });

        try {
          member = await fetchMemberForEmailV2(email_address);
          if (window.location.pathname !== '/tscoadmin') {
            const memberProfile = await fetchPlayerProfile(member.member_id);
            dispatchMemberProfile(memberProfile!);
          }
          if (!member.member_id) {
            // The first time we login with a new account, the backend won't have
            // an account for us yet, so we need to create one now
            const cognito_sub = await getSubFromCognitoUser(user);
            const first_name = await getFirstNameFromCognitoUser(user);
            const last_name = await getLastNameFromCognitoUser(user);
            const member_id = makeid(8);
            const email_validated = (user as any).attributes?.email_validated ? 1 : 0;

            console.log({
              cognito_sub,
              email_address,
              first_name,
              last_name,
              member_id,
              sport_id_default: 1,
            });
            await createMember({
              cognito_sub,
              email_address,
              first_name,
              last_name,
              member_id,
              sport_id_default: 1,
              email_validated: email_validated,
            });
            member = await fetchMemberForEmailV2(email_address);
            const memberProfile = await fetchPlayerProfile(member.member_id);
            dispatchMemberProfile(memberProfile!);
          }
        } catch (error: any) {
          console.log('Error', error);
        }

        setUser(user);
        setMember(member);
        dispatchSetMember(member);
        if (window.location.pathname !== '/tscoadmin') {
          getMemberAssociations(member);
        }
      } catch (error: any) {
        console.error(error);

        setUser(undefined);
        setMember(undefined);
        dispatchSetMember(undefined);
        setRefreshMember(() => () => {});
      }
      setIsLoadingData(false);
    };
    Hub.listen('auth', updateUser); // listen for login/signup events

    updateUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove('auth', updateUser); // cleanup
  }, [dispatchSetMember, dispatchMemberProfile, dispatchGetMemberAssociations]);

  return <CognitoAuthUserContext.Provider value={user}>{!isLoadingData && <MemberContext.Provider value={memberContext}>{!user || !member ? loggedOutComponent : children}</MemberContext.Provider>}</CognitoAuthUserContext.Provider>;
};

const mapStateToProps = (state: any) => ({
  memberState: state.memberReducer,
});

const mapDispatchToProps = {
  dispatchSetMember: (data: Member) => memberActions.setMemberData(data),
  dispatchGetMemberAssociations: (data: string) => memberAssociationsActions.getMemberAssociations(data),
  dispatchMemberProfile: (memberProfile: IPlayerProfile) => memberActions.setMemberProfile(memberProfile),
};

export default connect(mapStateToProps, mapDispatchToProps)(CognitoAuthProvider);
// export default CognitoAuthProvider;
